.form-time-picker .rdtSwitch {
  display: none;
}

.form-time-picker .rdtPicker {
  bottom: 50px;
}

.placement-down .rdtPicker {
  top: 50px;
  bottom: auto;
}
